import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";

const Header = () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "http://localhost:3000";
  const [employeeName, setEmployees] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.clear(); // Clear local storage
    navigate("/"); // Redirect to the homepage
  };

  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const id = query.get("emp_id");
  console.log("id " + id);

  useEffect(() => {
    const fetchdata = async () => {
      if (id) {
        try {
          const formData1 = new FormData();
          formData1.append("user_id", id);

          const response = await axios({
            method: "POST",
            url: `${API_BASE_URL}/Users/get_users_list`,
            data: formData1,
            headers: { "Content-Type": "multipart/form-data" },
          });

          const userData = response.data.results[0];
          setEmployees(userData.employee_name || "Unknown");
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchdata();
  }, [API_BASE_URL, id]);

  useEffect(() => {
    console.log("Employee Name Updated: ", employeeName);
  }, [employeeName]);

  const full_name = localStorage.getItem("full_name");
  const role_name = localStorage.getItem("role_name");


  
  return (
    <header id="page-topbar" className="page-topbar">
      <div className="layout-width">
        <div className="navbar-header">
          <div className="d-flex">
            {/* Logo Section */}
            <div className="navbar-brand-box horizontal-logo"></div>
            <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger material-shadow-none" id="topnav-hamburger-icon">
                    <span class="hamburger-icon">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>

            {/* Employee Name */}
            <div className="employee_name">
             
            {employeeName && (
              <div>
                <i className="ri-account-circle-line faright"></i> 
                <span className="user_name_heading">{employeeName}</span>
              </div>
            )}

            </div>
          </div>

          <div className="d-flex align-items-center">
            <div className="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                className="btn material-shadow-none"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <img
                    className="rounded-circle header-profile-user"
                    src="assets/images/users/avatar-1.jpg"
                    alt="Header Avatar"
                  />
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{full_name}</span>
                    <span className="d-none d-xl-block ms-1 fs-12 user-name-sub-text">{role_name}</span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <h6 className="dropdown-header">Welcome {full_name}!</h6>

                <a className="dropdown-item" href="/UserProfile">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> Profile
                </a>
                <a className="dropdown-item" href="/ChangePassword">
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> Change Password
                </a>

                <a className="dropdown-item" onClick={handleLogout} href="">
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                  <span className="align-middle">Logout</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
