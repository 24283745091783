import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false); 
    const [loading, setLoading] = useState(false);
    const [viewmessage, setMessages] = useState(null);
    const [viewlockouttime, setLockoutTime] = useState(null);
    const [remainingTime, setRemainingTime] = useState(null); // For countdown
    const [encryptedPassword, setEncryptedPassword] = useState("");
  

    const navigate = useNavigate();

    useEffect(() => {
        if (viewlockouttime) {
            // Convert lockout timestamp to seconds (assuming it's in "YYYY-MM-DD HH:mm:ss" format)
            const lockoutEndTime = new Date(viewlockouttime).getTime() / 1000;
            const currentTime = Math.floor(Date.now() / 1000);

            if (lockoutEndTime > currentTime) {
                // Start countdown
                const countdownInterval = setInterval(() => {
                    const remaining = lockoutEndTime - Math.floor(Date.now() / 1000);
                    if (remaining <= 0) {
                        clearInterval(countdownInterval);
                        setRemainingTime(null);
                        // const alerts = document.getElementsByClassName('alert-danger');
                        // for (let i = 0; i < alerts.length; i++) {
                        //     alerts[i].style.display = 'none';
                        // }
                        window.location.reload();
                        
                    } else {
                        setRemainingTime(remaining);
                    }
                }, 1000);

                // Clean up the interval on component unmount
                return () => clearInterval(countdownInterval);
            } else {
                setRemainingTime(null); // Account unlocked
            }
        }
    }, [viewlockouttime]);

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append("email", email);
            formData.append("password", password);

            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.post(`${API_BASE_URL}/validate_login`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setLockoutTime(response.data.lockout_timestamp_view);
            setMessages(response.data.message);

            if (response.data.success === 1) {
                const { mst_users_id, full_name, role_name, email: userEmail, token, mst_roles_id, password_change } = response.data.data[0];
            
                // Store data in localStorage
                localStorage.setItem("mst_users_id", mst_users_id);
                localStorage.setItem("full_name", full_name);
                localStorage.setItem("email", userEmail);
                localStorage.setItem("token", token);
                localStorage.setItem("role_name", role_name);
                localStorage.setItem("mst_roles_id", mst_roles_id);
                localStorage.setItem("password_change", password_change);
            
                // Delay redirection or check role and navigate
                if (password_change == 0 || password_change == '0') {
                    navigate("/ChangePassword");
                } else {
                    setTimeout(() => {
                        if (localStorage.getItem("mst_roles_id") === "4") {
                            navigate("/Home");
                        } else if (localStorage.getItem("mst_roles_id") === "3") {
                            navigate(`/MainPage?emp_id=${localStorage.getItem("mst_users_id")}`);
                        } else {
                            navigate("/dashboard");
                        }
                    }, 100);  // Delay added to ensure state updates
                }
            } else {
                setIsVisible(true);
            }
            
        } catch (error) {
            setIsVisible(true);
        } finally {
            setLoading(false);
        }
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    // Helper function to format remaining time (hh:mm:ss)
    const formatTime = (time) => {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = time % 60;
        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    console.log(viewmessage);

    const handlePasswordChange = async (e) => {
        const passwordValue = e.target.value;
        setPassword(passwordValue);
    
        if (passwordValue.trim() === "") {
            setEncryptedPassword("");
            return;
        }
    
        // Disable sign-in button while encrypting password
        const signInButton = document.querySelector(".sign_in");
        if (signInButton) {
            signInButton.disabled = true;
        }
    
        try {
            const formData = new FormData();
            formData.append("password", passwordValue); // Append the password to FormData
    
           
            const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
            const response = await axios.post(`${API_BASE_URL}/encrypt_text`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }, 
            });
            
    
            const responseData = response.data;
    
            if (responseData.encryptedPassword) {
                setEncryptedPassword(responseData.encryptedPassword);
                if (signInButton) {
                    signInButton.disabled = false;
                }
            } else if (responseData.error) {
             
                if (signInButton) {
                    signInButton.disabled = true;
                }
            }
        } catch (error) {
            console.error("Error encrypting password:", error);
        
            if (signInButton) {
                signInButton.disabled = true;
            }
        }
    };
    
    
    return (
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay"></div>
            <div className="auth-page-content overflow-hidden pt-lg-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card overflow-hidden card-bg-fill galaxy-border-none">
                                <div className="row justify-content-center g-0">
                                    <div className="col-lg-6">
                                        <div className="p-lg-5 p-4 auth-one-bg h-100">
                                            <div className="bg-overlay" style={{ background: "#fff" }}></div>
                                            <div className="position-relative h-100 d-flex flex-column">
                                                <div className="mb-4">
                                                    <a href="" className="d-block">
                                                        <img src="assets/images/renew-logo.png" alt="" height="18" />
                                                    </a>
                                                </div>
                                                <div className="mt-auto">
                                                    <div className="mb-3">
                                                        <i className="ri-double-quotes-l display-4 text-success"></i>
                                                    </div>

                                                    <div id="qoutescarouselIndicators" className="carousel slide" data-bs-ride="carousel">
                                                        <div className="carousel-indicators">
                                                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                                            <button type="button" data-bs-target="#qoutescarouselIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                                        </div>
                                                        <div className="carousel-inner text-center text-white-50 pb-5">
                                                            <div className="carousel-item active">
                                                                <p className="fs-15 fst-italic" style={{ color: "#000" }}>" Track Time, Boost Productivity "</p>
                                                            </div>
                                                            <div className="carousel-item">
                                                                <p className="fs-15 fst-italic" style={{ color: "#000" }}>" Master Your Time, Elevate Your Work."</p>
                                                            </div>
                                                            <div className="carousel-item">
                                                                <p className="fs-15 fst-italic" style={{ color: "#000" }}>" Smart Time Tracking for Smarter Workflows! "</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="p-lg-5 p-4">
                                            <div>
                                                <h5 className="text-primary">Welcome Back... !</h5>
                                                <p className="text-muted">Sign in to continue to Timesheet Manager</p>
                                            </div>

                                            <div className="mt-4">
                                                <form onSubmit={handleSubmit}>
                                                    <div className="mb-3">
                                                        <label htmlFor="username" className="form-label">Email</label>
                                                        <input type="text" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} id="username" placeholder="Enter email" />
                                                    </div>

                                                    <div className="mb-3">
                                                        <label className="form-label" htmlFor="password-input">Password</label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            
                                                            <input 
                                                                type={isPasswordVisible ? "text" : "password"} 
                                                                value={password} 
                                                                // onChange={(e) => setPassword(e.target.value)} 
                                                                onChange={handlePasswordChange}
                                                                className="form-control pe-5 inputPassword" 
                                                                placeholder="Enter password" 
                                                                id="inputPassword" 
                                                            />
                                                           
                                                           <input
                                                                type="hidden"
                                                                id="encryptedPassword"
                                                                name="encryptedPassword"
                                                                value={encryptedPassword} // Hidden field to hold encrypted password
                                                            />

                                                            <button 
                                                                className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none" 
                                                                type="button" 
                                                                id="password-addon" 
                                                                onClick={togglePasswordVisibility}
                                                            >
                                                                <i className={`ri-eye-${isPasswordVisible ? "fill" : "fill"} align-middle`}></i>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="mt-4">
                                                        <button
                                                            className="btn btn-success w-100"
                                                            type="submit"
                                                            disabled={loading}
                                                        >
                                                            {loading ? "Signing In..." : "Sign In"}
                                                        </button>
                                                    </div>
                                                    {loading && (
                                                        <div className="text-center mt-3">
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        </div>
                                                    )} {viewmessage !== null && (
                                                            <div className="alert alert-danger mt-3 border-0 mb-xl-0 material-shadow" style={{ display: isVisible ? "block" : "none" }} role="alert">
                                                                <strong> Error! </strong> {viewmessage}
                                                            </div>   )}   
                                                    {remainingTime !== null && (
                                                            <div className="alert alert-danger mt-3 border-0 mb-xl-0 material-shadow" style={{ display: isVisible ? "block" : "none" }} role="alert">
                                                                <span id="lockout-timer">
                                                                Too many login attempts.  Try again in {formatTime(remainingTime)}
                                                                </span>
                                                            </div>   
                      
                                                    )}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer galaxy-border-none">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-lg-12">
                            <p className="text-white-50 text-center" style={{ marginTop: "30px" }}>© 2025 Timesheet Manager</p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Login;
