import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
// import { Link } from 'react-router-dom';

const Users = () => {
  const [toastMessage, setToastMessage] = useState(null);
  const [excelresponse, setExcelresponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);  // Added loading state
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Handle toast messages from localStorage
  useEffect(() => {
    const message = localStorage.getItem("toastMessage");
    if (message) {
      setToastMessage(message);
      localStorage.removeItem("toastMessage");
    }
  }, []);

  useEffect(() => {
    if (toastMessage) {
      toast.success(toastMessage);
    }
  }, [toastMessage]);

  // Handle file input and drag-drop functionality
  useEffect(() => {
    const fileInputElement = document.querySelector("#et_pb_contact_brand_file_request_0");
    const labelElement = document.querySelector('label[for="et_pb_contact_brand_file_request_0"]');

    const handleClick = () => {
      const fileNamesElements = document.querySelectorAll(".file_names");
      fileNamesElements.forEach((element) => element.remove());
    };

    const handleDragOver = (event) => {
      event.preventDefault();
      labelElement.className = "et_pb_contact_form_label changed";
    };

    const handleDragLeave = () => {
      labelElement.className = "et_pb_contact_form_label";
    };

    const handleDrop = (event) => {
      event.preventDefault();
      labelElement.className = "et_pb_contact_form_label";
      const files = event.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        const fileNameDiv = document.createElement("div");
        fileNameDiv.className = "file_names";
        fileNameDiv.textContent = files[i].name;
        labelElement.appendChild(fileNameDiv);
      }
    };

    const handleChange = () => {
      const file = fileInputElement.files[0];
      if (file) {
        const fileNameDiv = document.createElement("div");
        fileNameDiv.className = "file_names";
        fileNameDiv.textContent = file.name;
        labelElement.appendChild(fileNameDiv);
        labelElement.style.backgroundColor = "#eee9ff";
      }
    };

    if (fileInputElement && labelElement) {
      fileInputElement.addEventListener("click", handleClick);
      labelElement.addEventListener("dragover", handleDragOver);
      labelElement.addEventListener("dragleave", handleDragLeave);
      labelElement.addEventListener("drop", handleDrop);
      fileInputElement.addEventListener("change", handleChange);
    }

    return () => {
      if (fileInputElement && labelElement) {
        fileInputElement.removeEventListener("click", handleClick);
        labelElement.removeEventListener("dragover", handleDragOver);
        labelElement.removeEventListener("dragleave", handleDragLeave);
        labelElement.removeEventListener("drop", handleDrop);
        fileInputElement.removeEventListener("change", handleChange);
      }
    };
  }, []);

  // Upload Excel function
  const upload_excel = async (event) => {
    event.preventDefault();
    setIsLoading(true);  // Show loader

    try {
      const fileInput = document.querySelector("#et_pb_contact_brand_file_request_0");
      const file = fileInput?.files[0];

      if (!file) {
        toast.error("Please select a file to upload.");
        setIsLoading(false);  // Hide loader
        return;
      }

      const formData = new FormData();
      formData.append("user_id", localStorage.getItem("mst_users_id") || "");
      formData.append("file-upload", file);

      const apiEndpoint = `${API_BASE_URL}/Users/user_upload_excel`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
     
      console.log("response.data.success " + response.data.success);
      if (response.data.success === 1) {
        toast.success(response.data.message);
        setExcelresponse(response.data.success);
        // localStorage.setItem("toastMessage", response.data.message);
        // window.location.href = "/Users";
        fileInput.value = ""; // Reset file input
        const fileNames = document.querySelector(".file_names");
        if (fileNames) {
          fileNames.textContent = ""; // Clear displayed file names if applicable
        }
        
      } else {
        setExcelresponse(response.data.success);
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Error during file upload:", error);
      toast.error("An error occurred during file upload.");
    } finally {
      setIsLoading(false);  // Hide loader
    }
  };


  
const downloadSampleCSV = () => {
  if(!confirm("Are you sure want to Download Sample CSV ..?")){
    return false;
  }

  const csvData = [
    ["Employee ID", "Employee Name", " Email", " Mobile", "Department Code", "Role (3-employee 4-delegate user)", "Password", "Date of Joining", "Date of Birth", "Address", " Employees"], 
    ["ABC-13132", "Harish Rathod", "harishrolls@gmail.com", "9087890987", "101", "3", "123456", "27-12-2024", "01-06-1993", "Karnataka, Bangalore", ""],
  
    ["ABC-13134", "Harish Rathod2", "harishrolls2@gmail.com", "9087890987", "101", "4", "123456", "27-12-2024", "01-06-1993", "Karnataka, Bangalore", "ABC-13132,ABC-13133"],
  ];

  // Convert CSV data to a string, enclosing values that contain commas in quotes
  const csvString = csvData
    .map((row) => row
      .map(cell => {
        // If the cell contains a comma or double quotes, enclose it in double quotes and escape any existing double quotes
        if (cell && (cell.includes(',') || cell.includes('"'))) {
          return `"${cell.replace(/"/g, '""')}"`;
        }
        return cell;
      })
      .join(","))
    .join("\n");

  // Create a Blob object with CSV data and set up a download link
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "upload_users.csv"; // Name of the file to be downloaded
  link.click(); // Trigger the download
};

const downloadDepartmentCode = async () => {
  if(!confirm("Are you sure want to Download Department Codes..?")){
    return false;
  }
  const apiEndpoint = `${API_BASE_URL}/Department/download_department_code`;

  // Create a new FormData object (add any data or file you want to send)
  const formData = new FormData();

  // If you need to append data to the formData, you can do it like this:
  // formData.append('key', value);

  try {
    const response = await axios.post(apiEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      responseType: 'blob', // This ensures the response is treated as a file (blob)
    });

    // Create a link element to trigger the file download
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(new Blob([response.data]));
    link.href = url;
    link.setAttribute('download', 'department_code.csv'); // Change the filename as needed
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link element
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading department code:', error);
  }
};



  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row page-title-box">
            <div className="col-lg-3">
              <h4 className="mb-sm-0">Upload Users</h4>
            </div>
            <div className="col-lg-9 text-right">
                <button className="btn btn-sm btn-success" onClick={downloadSampleCSV}>Download Sample CSV.</button>
                <button className="btn btn-sm btn-warning" onClick={downloadDepartmentCode}>Download  Department Codes</button>
            </div>
          </div>
          <div className="row upload_excel_styles">
            <div className="col-12">
              <div className="container text-center">
               
                {isLoading && <div className="loader">Uploading...</div>} {/* Display loader */}
                <label htmlFor="et_pb_contact_brand_file_request_0" className="et_pb_contact_form_label">
                  Drag & Drop or Click to Upload
                </label>
                <input type="file" id="et_pb_contact_brand_file_request_0" accept=".csv" className="file-upload" />
                <button type="button" style={{ marginTop: "-100px" }} className="btn btn-sm btn-primary btn_upload" onClick={upload_excel} disabled={isLoading}>
                  {isLoading ? "Uploading..." : "Upload"}  {/* Show loading text */}
                </button>

                {excelresponse === 0 && (
                  <a
                    style={{ marginTop: "-100px" }}
                    href={`${API_BASE_URL}/Users/downloadErrors`}
                    className="btn btn-sm btn-danger downloadErrors"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Download Error File
                  </a>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
