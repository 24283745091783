import React, { useState } from "react";
import {
  addWeeks,
  subWeeks,
  startOfWeek,
  format,
  addDays,
  endOfMonth,
 // isSameDay,
  isBefore,
} from "date-fns";

const App = () => {
  /*** Filter State ***/
  const [filterYear, setFilterYear] = useState(new Date().getFullYear());
  const [filterMonth, setFilterMonth] = useState("");
  const [filterWeekData, setFilterWeekData] = useState([]);
  const [filterSelectedWeek, setFilterSelectedWeek] = useState("");

  /*** Table State ***/
  const [tableCurrentWeekStart, setTableCurrentWeekStart] = useState(new Date());

  const getFinancialYearStart = (year) => new Date(`${year}-04-01`);

  /*** Filter Functions ***/
  const filterCalculateFinancialYearWeeks = (year, month) => {
    const isPreviousFY = ["01", "02", "03"].includes(month);
    const financialYearStart = getFinancialYearStart(isPreviousFY ? year - 1 : year);
    const startDate = new Date(`${year}-${month}-01`);
    const endDate = endOfMonth(startDate);

    const weeks = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      const firstDayOfWeek = startOfWeek(currentDate, { weekStartsOn: 1 });
      const weekEndDate = addDays(firstDayOfWeek, 6);

      // Ensure the week falls within the selected month
      if (
        isBefore(firstDayOfWeek, startDate) ||
        isBefore(endDate, weekEndDate)
      ) {
        currentDate = addDays(currentDate, 7);
        continue;
      }

      const weekNumber =
        Math.ceil((firstDayOfWeek - financialYearStart) / (7 * 24 * 3600 * 1000)) + 1;

      weeks.push({
        week: weekNumber,
        startDate: format(firstDayOfWeek, "yyyy-MM-dd"),
        endDate: format(addDays(firstDayOfWeek, 4), "yyyy-MM-dd"), // Mon-Fri
      });
      currentDate = addDays(currentDate, 7);
    }
    return weeks;
  };

  const filterApplyWeek = () => {
    const weekNumber = parseInt(filterSelectedWeek, 10);
    if (!weekNumber || isNaN(weekNumber)) return;

    const financialYearStart = getFinancialYearStart(filterYear);
    const weekStartDate = addDays(financialYearStart, (weekNumber - 1) * 7);
    setTableCurrentWeekStart(weekStartDate);
  };

  /*** Table Functions ***/
  const tableNavigateWeek = (direction) => {
    setTableCurrentWeekStart((prev) =>
      direction === "prev" ? subWeeks(prev, 1) : addWeeks(prev, 1)
    );
  };

  const tableGetWeekDates = () => {
    const weekDates = [];
    const startOfWeekDate = startOfWeek(tableCurrentWeekStart, { weekStartsOn: 1 });
    for (let i = 0; i < 5; i++) {
      weekDates.push(addDays(startOfWeekDate, i));
    }
    return weekDates;
  };

  const tableWeekDates = tableGetWeekDates();
  const currentYear = new Date().getFullYear();

  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="App">
            <h1>Financial Year Week Numbers</h1>

            {/* Filter Section */}
            <div>
              <label>Filter Year: </label>
              <select
                value={filterYear}
                onChange={(e) => setFilterYear(parseInt(e.target.value))}
              >
                {[...Array(currentYear - 2018)].map((_, i) => {
                  const year = 2019 + i;
                  return (
                    <option key={year} value={year}>
                      {year}-{year + 1}
                    </option>
                  );
                })}
              </select>
            </div>

            <div>
              <label>Filter Month: </label>
              <select
                value={filterMonth}
                onChange={(e) => {
                  setFilterMonth(e.target.value);
                  setFilterWeekData(
                    filterCalculateFinancialYearWeeks(filterYear, e.target.value)
                  );
                }}
              >
                <option value="">Select Month</option>
                {[
                  "04",
                  "05",
                  "06",
                  "07",
                  "08",
                  "09",
                  "10",
                  "11",
                  "12",
                  "01",
                  "02",
                  "03",
                ].map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>

            {filterWeekData.length > 0 && (
              <div>
                <label>Filter Week: </label>
                <select
                  value={filterSelectedWeek}
                  onChange={(e) => setFilterSelectedWeek(e.target.value)}
                >
                  <option value="">Select Week</option>
                  {filterWeekData.map((week) => (
                    <option key={week.week} value={week.week}>
                      Week {week.week} ({week.startDate} - {week.endDate})
                    </option>
                  ))}
                </select>
                <button onClick={filterApplyWeek}>Apply Week</button>
              </div>
            )}

            {/* Table Section */}
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <button
                className="btn btn-success"
                onClick={() => tableNavigateWeek("prev")}
              >
                &lt; Prev
              </button>
              <h3>{format(tableCurrentWeekStart, "MMMM yyyy")}</h3>
              <button
                className="btn btn-success"
                onClick={() => tableNavigateWeek("next")}
              >
                Next &gt;
              </button>
            </div>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Project</th>
                  {tableWeekDates.map((date, i) => (
                    <th key={i}>{format(date, "EEE, dd MMM")}</th>
                  ))}
                  <th>Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Table body content */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
