
import React, { useState, useEffect } from 'react';
import axios from "axios";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Dashboard = () =>{
    const navigate = useNavigate();
    
   
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [employees, setEmployees] = useState([]);
    //const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    var avoidTerms = [

        "company", "corporation", "enterprise", "organization", "business",
        "headquarters", "office", "branch", "division", "subsidiary",
        "industry", "sector", "market", "commerce", "trade", "commercial",
        "financial", "investment", "bank", "insurance", "capital", "venture",
        "product", "service", "brand", "logo", "trademark", "patent", "copyright",
        "market", "customer", "client", "supplier", "vendor", "partner",
        "collaborator",
        "management", "executive", "director", "manager", "employee", "staff",
        "meeting", "conference", "presentation", "project", "team", "task", "goal",
        "board", "shareholder", "stakeholder", "quarterly", "annual", "report",
        "audit", "finance", "strategy", "partnership", "strategic", "initiative",
        "innovation", "research", "development", "productivity", "efficiency",
        "customer", "clientele", "retail", "wholesale", "manufacturing", "supplier",


        "location", "city", "country", "state", "region", "geography", "place",
        "continent", "ocean", "sea", "mountain", "valley", "desert", "rainforest",
        "island", "archipelago", "peninsula", "plateau", "glacier", "tundra",


        "technology", "software", "hardware", "network", "server", "database",
        "programming", "developer", "code", "algorithm", "data", "analytics",
        "cloud", "virtualization", "containerization", "microservices", "agile",
        "scrum", "devops", "frontend", "backend", "fullstack", "framework",
        "algorithmic", "automation", "integration", "middleware",


        "security", "password", "authentication", "authorization", "encryption",
        "firewall", "intrusion", "hacking", "attack", "defense",
        "biometrics", "forensics", "penetration", "vulnerability", "mitigation",
        "incident", "response", "compliance", "regulation", "privacy",
        "confidentiality",


        "admin", "password", "123456", "qwerty", "letmein", "welcome",
        "letmein", "welcome123", "password123", "admin123", "qwerty123",
        "changeme", "password1", "p@ssw0rd", "123abc", "abcdef", "qwertyuiop",


        "firstname", "lastname", "birthdate", "username", "email", "phone",
        "firstname123", "lastname123", "birthdate123", "username123", "email123",
        "phone123", "address123", "city123", "country123", "zipcode123",


        "admin", "root", "superuser", "master", "test", "demo", "guest",
        "webmaster",
        "webmaster123", "testuser", "testaccount", "demoaccount", "guestuser",
        "superadmin", "rootuser", "systemadmin", "masteruser", "support",
        "helpdesk",
        "red", "blue", "green", "yellow", "orange", "purple", "lion", "tiger",
        "elephant",
        "giraffe", "zebra", "kangaroo", "koala", "penguin", "cactus", "butterfly",
        "dragonfly",
        "monday", "february", "summer", "autumn", "december", "january",
        "123", "456", "789", "321", "654", "987",
        "qwe", "asd", "zxc", "mnb", "poi", "lkj",
        "aaa", "bbb", "ccc", "xyz", "1234", "5678", "abcd", "abcd1234", "Aarav",
        "Aarya", "Amit", "Ananya", "Arjun", "Ayush", "Divya", "Harsh", "Ishita",
        "Rahul", "Riya", "Suhana", "Varun", "Vidya", "Yash", "Arvind", "Deepika",
        "Kiran", "Manju", "Prakash", "Radha", "Rajesh", "Shilpa", "Suresh", "Swati",
        "Arunima", "Debashish", "Indrani", "Kunal", "Madhuri", "Partho", "Ritwik",
        "Sharmila", "Subhashis", "Tithi", "Amandeep", "Gurpreet", "Harpreet",
        "Jaswinder", "Manpreet", "Navjot", "Simran", "Sukhbir", "Tejinder",
        "Varinder", "Aishwarya", "Ganesh", "Jyoti", "Madhav", "Manisha", "Ravi",
        "Shalini", "Vijay", "Vishakha", "Yogesh",
        "Arunima", "Debashish", "Indrani", "Kunal", "Madhuri", "Partho", "Ritwik",
        "Sharmila", "Subhashis", "Tithi", "Amandeep", "Gurpreet", "Harpreet",
        "Jaswinder", "Manpreet", "Navjot", "Simran", "Sukhbir", "Tejinder",
        "Varinder", "Aishwarya", "Ganesh", "Jyoti", "Madhav", "Manisha", "Ravi",
        "Shalini", "Vijay", "Vishakha", "Yogesh", "Bhavya", "Chirag", "Dhara",
        "Kartik", "Minal", "Parth", "Rupal", "Tanvi", "Udit", "Vrinda", "Arjunan",
        "Dhanalakshmi", "Gowtham", "Kaviya", "Mohan", "Priya", "Rajkumar",
        "Shanthi", "Sundar", "Vasanthi", "Anitha", "Gopalan", "Kalyani", "Krishnan",
        "Maya", "Narayanan", "Rohini", "Suresh", "Uma", "Vimala", "Akshay", "Deepa",
        "Girish", "Kavya", "Manjunath", "Nandini", "Pradeep", "Roopa", "Vinay",
        "Yamini", "Sharma", "Patel", "Kumar", "Singh", "Chauhan", "Reddy",
        "Mukherjee", "Nair", "Khan", "Gupta",
        "Arun", "Bhavana", "Chitra", "Dinesh", "Gayathri", "Hari", "Indira",
        "Jagan", "Latha", "Mohan",
        "Padma", "Rajani", "Sridhar", "Uma", "Venkat", "Vidyalakshmi", "Yamini",
        "Anand", "Bhargavi", "Chandrasekhar", "Divakar", "Gowri", "Hemanth",
        "Janani", "Karthik", "Lakshmi", "Manoj",
        "Nandini", "Pradeep", "Radhika", "Suresh", "Triveni", "Vasudev", "Vidya",
        "Yogita",
        "Aditi", "Balaji", "Charulatha", "Dinesh", "Geetha", "Hariharan",
        "Jayalakshmi", "Kumaravel", "Latha", "Mohan",
        "Nalini", "Prakash", "Rajalakshmi", "Srinivasan", "Tarun", "Uma",
        "Venkatraman", "Vidhusha", "Yuvan", "Zubaida",
        "Anusha", "Bharath", "Chitra", "Dilip", "Gayathri", "Hemanth", "Janani",
        "Karthik", "Lakshman", "Meenakshi",
        "Naveen", "Pallavi", "Rajeshwari", "Sampath", "Sowmya", "Thirumal", "Uthra",
        "Veerabhadra", "Vidhya", "Yamini",
       
    ];
   

    useEffect(() => {
        const fetchdata = async () => {
            try {
                const formData1 = new FormData();
                formData1.append("user_id", localStorage.getItem("mst_users_id"));

                const response = await axios({
                    method: 'POST',
                    url: `${API_BASE_URL}/Users/get_users_list`,
                    data: formData1,
                    headers: { "Content-Type": "multipart/form-data" }
                });

                const userData = response.data.results[0];
                setEmployees(userData);
            } catch (error) {
                console.error('Error fetching user data:', error);
              
            }
         
        };

        fetchdata();
    }, [API_BASE_URL]);


    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
      
        const oldPassword = document.getElementsByClassName("old_password")[0]?.value || "";
        if(oldPassword==''){
            toast.error("Old password cannot be empty.");
            return;
        }
        const newPassword = document.getElementsByClassName("new_password")[0]?.value || "";
        const confirmPassword = document.getElementsByClassName("confirm_password")[0]?.value || "";
        const email = document.getElementsByClassName("email_id")[0]?.value || "";
      
        const passwordError = document.getElementById("passwordError");
        const passwordStrength = document.getElementById("passwordStrength");
      
        // Validation logic
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;
        const containsAvoidTerms = avoidTerms.some((term) =>
          newPassword.toLowerCase().includes(term.toLowerCase())
        );
      
        if (!newPassword) {
          toast.error("New password cannot be empty.");
          return;
        }
      
        if (containsAvoidTerms) {
          toast.error("Password contains forbidden terms or slang.");
          passwordError.textContent = "Password contains forbidden terms or slang.";
          return;
        }
      
        if (newPassword.length < 8) {
          toast.error("Password must be at least 8 characters long.");
          passwordError.textContent = "Password must be at least 8 characters long.";
          return;
        }
      
        if (!passwordRegex.test(newPassword)) {
          toast.error("Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character.");
          passwordError.textContent = "Password must contain at least 1 uppercase, 1 lowercase, and 1 special character.";
          return;
        }
      
        if (newPassword !== confirmPassword) {
          toast.error("New password and confirm password do not match.");
          passwordError.textContent = "Passwords do not match.";
          return;
        }
      
        passwordError.textContent = "";
        passwordStrength.textContent = "Password Strength: Strong";
      
        if (!confirm("Are you sure you want to change the password?")) return;
      
        try {
          const formData = new FormData();
          formData.append("old_password", oldPassword);
          formData.append("new_password", newPassword);
          formData.append("confirm_password", confirmPassword);
          formData.append("email", email);
          formData.append("mst_users_id", localStorage.getItem("mst_users_id"));
      
          const response = await axios.post(
            `${API_BASE_URL}/Users/changepassword_web`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          );
      
          if (response.data.success === 1) {
            toast.success(response.data.message);
            localStorage.clear();
            navigate("/");
            localStorage.setItem("toastMessage", response.data.message);
          } else {
            toast.error(response.data.message);
          }
        } catch (error) {
          console.error("Error during submission:", error);
          toast.error("An error occurred while changing the password.");
        }
      };
      


    const handlePasswordValidation = (e) => {
        const passwordInput = e.target;
        const password = passwordInput.value;
        const passwordError = document.getElementById('passwordError');
        const passwordStrength = document.getElementById('passwordStrength');

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;

        const containsAvoidTerms = avoidTerms.some(term => password.toLowerCase().includes(term.toLowerCase()));

        if (containsAvoidTerms) {
            passwordError.innerHTML = `Password should not contain forbidden terms or slang.`;
            passwordStrength.textContent = '';
            e.preventDefault(); // Prevent form submission
            return false;
        } else {
            passwordError.textContent = '';
            if (password.length < 8) {
                passwordError.textContent = 'Password must be at least 8 characters long.';
                passwordStrength.textContent = '';
                e.preventDefault(); // Prevent form submission
                return false;
            } else {
                if (!passwordRegex.test(password)) {
                    passwordError.textContent = 'Password must contain at least 1 uppercase letter, 1 lowercase letter, and 1 special character.';
                    passwordStrength.textContent = '';
                } else {
                    passwordStrength.textContent = 'Password Strength: Strong';
                }
            }
        }
    };
    
    return(
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    {/* <!-- start page title --> */}
                    <div className="row mb-3 pb-1 ">
                        <div className="col-12">
                            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                <div className="flex-grow-1">
                                  
                                   
                                </div>
                                <div className="mt-3 mt-lg-0">                                    
                                </div>
                            </div>
                            {/* <!-- end card header --> */}
                        </div>                        
                    </div>
                    <div className='row'>
                        <div className='card'>
                            <div className='card-header'>
                                <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                    <div className="flex-grow-1">
                                        <h4 className="fs-16 mb-1">Change Password</h4>
                                    
                                    </div>
                                    <div className="mt-3 mt-lg-0">                                    
                                    </div>
                                </div>
                            </div>
                            <div className='card-body'>

                                <form  id="myForm" onSubmit={() => handleSubmit()}>
                            
                                    <div className="row">
                                    
                                        <div className="col-xxl-3 col-md-6 form-group">
                                            <div>
                                                <label for="basiInput" className="form-label">User Name</label>
                                                <input type="text" className="form-control"  value={employees.employee_name} readOnly id="basiInput"/>
                                            </div>
                                        </div>
                                        
                                        <div className="col-xxl-3 col-md-6 form-group">
                                            <div>
                                                <label for="basiInput" className="form-label">Email Id</label>
                                                <input type="text" className="form-control email_id" value={employees.employee_email} readOnly id="basiInput"/>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-md-6 form-group">
                                            <div>
                                                <label for="basiInput" className="form-label">Role Name</label>
                                                <input type="text" className="form-control" value={employees.role_name} readOnly  id="basiInput"/>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-md-6 form-group">
                                            <div>
                                                <label for="basiInput" className="form-label">Department Name</label>
                                                <input type="text" className="form-control" value={employees.department_name} readOnly id="basiInput"/>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-md-6 form-group">
                                            <div>
                                                <label for="basiInput" className="form-label">Old Password</label>
                                                <input type="text" className="form-control old_password"  id="basiInput"/>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-md-6 form-group">
                                            <div>
                                                <label for="basiInput" className="form-label">New Password</label>
                                                <input type="password"   onKeyUp={handlePasswordValidation} className="form-control new_password"  id="basiInput"/>
                                                <span id="passwordError"></span>
                                                <span id="passwordStrength"></span>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-md-6 form-group">
                                            <div>
                                                <label for="basiInput" className="form-label">Confirm Password</label>
                                                <input type="password" className="form-control confirm_password"  id="basiInput"/>
                                                <span id="passwordnotmatch"></span>
                                            </div>
                                        </div>
                                        <div className="col-xxl-3 col-md-6 form-group">
                                            <div>
                                            <button type='button' onClick={() => handleSubmit()} className='btn btn-sm btn-primary mt-4'>Submit</button>
                                            </div>
                                        </div>
                                    </div> 
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <!-- end page title --> */}
                </div>
                {/* <!-- container-fluid --> */}
            </div>
            {/* <!-- End Page-content --> */}
            
        </div>
            
    )
}

export default Dashboard;