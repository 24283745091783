import React, { useState, useEffect } from "react";
import { addWeeks, subWeeks, startOfWeek, format, addDays, differenceInWeeks } from "date-fns";
import axios from "axios";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";

const FinancialYearDataEntry = () => {

  const [employees, setEmployees] = useState([]);
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Loader state

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const id = query.get("emp_id");

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


  const getFinancialYearStart = (date) => {
    // Financial year starts on April 1st
    const year = date.getMonth() >= 3 ? date.getFullYear() : date.getFullYear() - 1;
    return new Date(year, 3, 1); // April 1st of the given year
  };

  const getFinancialWeek = (date) => {
   //console.log("get financial week date "+date);
    const financialYearStart = getFinancialYearStart(date); // April 1 of the financial year
    const financialYearEnd = new Date(financialYearStart.getFullYear() + 1, 2, 31); // March 31 of the next year
  
    if (date >= financialYearStart && date <= financialYearEnd) {
      // Calculate week number from the start of the financial year
      const weekStart = startOfWeek(financialYearStart, { weekStartsOn: 1 });
      return differenceInWeeks(startOfWeek(date, { weekStartsOn: 1 }), weekStart) + 1;
    } else if (date > financialYearEnd) {
      // Handle transition to the next financial year
      const nextFinancialYearStart = new Date(financialYearStart.getFullYear() + 1, 3, 1); // April 1 of next year
      const nextWeekStart = startOfWeek(nextFinancialYearStart, { weekStartsOn: 1 });
      return differenceInWeeks(startOfWeek(date, { weekStartsOn: 1 }), nextWeekStart) + 1;
    } else {
      // If date is before the current financial year (unlikely in your case)
      return 0;
    }

  };
  
  
  const [currentWeekStart, setCurrentWeekStart] = useState(new Date());

  const navigateWeek = (direction) => {
    if (direction === "prev") {
      setCurrentWeekStart((prev) => {
        const newStart = subWeeks(prev, 1);
      
        return newStart;
      });
    } else {
      setCurrentWeekStart((prev) => {
        const newStart = addWeeks(prev, 1);
       
        return newStart;
      });
    }
  };


  const getWeekDates = () => {
    const weekDates = [];
    const startOfWeekDate = startOfWeek(currentWeekStart, { weekStartsOn: 1 });
    for (let i = 0; i < 5; i++) {
      weekDates.push(addDays(startOfWeekDate, i));
    }
    return weekDates;
  };

  const weekDates = getWeekDates();
  var year_data = format(currentWeekStart, "yyyy");
   //console.log("currentWeekStart "+currentWeekStart);
  var financialWeek = getFinancialWeek(currentWeekStart);
  //console.log("financialWeek "+financialWeek);

  useEffect(() => {
    const fetchdata = async () => {
      if (id) {
        try {
          const formData1 = new FormData();
          formData1.append("user_id", id);

          const response = await axios({
            method: "POST",
            url: `${API_BASE_URL}/Users/get_users_list`,
            data: formData1,
            headers: { "Content-Type": "multipart/form-data" },
          });

          const userData = response.data.results[0];
          setEmployees(userData.employee_name);
          console.log(employees);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchdata();
  }, [API_BASE_URL, id]);

  useEffect(() => {
    const fetchprojects = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/Project/get_all`);
        setProjects(response.data.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchprojects();
  }, [API_BASE_URL]);

  const handleSubmit = (status) => {

    if(status=='0'){

      if(!confirm("Are you sure want to Save in Draft...?")){
        return false;
      } 
    }
    if(status=='1'){

      if(!confirm("Are you sure want to Submit...?")){
        return false;
      } 
    }
    if(status=='2'){

      if(!confirm("Are you sure want to Approve...?")){
        return false;
      } 
    }

    const timesheet_data = [];
    
    var bool= false;
    const rows_datewise_function = document.querySelectorAll(`#week_table tbody tr .project_id`);
    rows_datewise_function.forEach((row, index) => {
      console.log(index);
      const projectvalue = row.value;
      if(projectvalue==''){
        alert("Please Select the Project to Proceed..!");
        bool = true;
        return false;
      }
    });

    if (bool) {
      return false;
    }

    // Loop through each date in the weekDates array
    weekDates.forEach((date, dayIndex) => {
      console.log(dayIndex)
      const formattedDate = format(date, "yyyy-MM-dd");
  
      // Select the rows with the correct date
      const rows_datewise = document.querySelectorAll(`#week_table tbody tr .date_${formattedDate}`);
  
      rows_datewise.forEach((row, index) => {
        console.log(index);
        const project_id = row.getAttribute('project_id');
        const mst_timesheets_id = row.getAttribute('mst_timesheets_id') || 0;
        const hrs = row.value;
  
        // Create an object with the desired structure
        const rowData = {
          user_id: id,
          updated_user_id: localStorage.getItem("mst_users_id"),
          date: formattedDate,
          mst_projects_id: project_id,
          mst_timesheets_id: mst_timesheets_id,
          hrs: hrs,
          week_no: financialWeek,
          year: format(currentWeekStart, "yyyy"),
          status: status
        };
  
        // Push the object to the data array
        timesheet_data.push(rowData);
      });
    });
  
    // Create a FormData object
    const formData = new FormData();
  
    // Append data to FormData in JSON format
    timesheet_data.forEach((item, index) => {
      formData.append(`data[${index}][date]`, item.date);
      formData.append(`data[${index}][hrs]`, item.hrs);
      formData.append(`data[${index}][mst_projects_id]`, item.mst_projects_id);
      formData.append(`data[${index}][mst_timesheets_id]`, item.mst_timesheets_id);
      formData.append(`data[${index}][status]`, item.status);
      formData.append(`data[${index}][updated_user_id]`, item.updated_user_id);
      formData.append(`data[${index}][user_id]`, item.user_id);
      formData.append(`data[${index}][week_no]`, item.week_no);
      formData.append(`data[${index}][year]`, item.year);
    });
  
    // Send the FormData to the API
    axios
      .post(`${API_BASE_URL}/Timesheet/add_timesheet_via_web`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(()=>{
        fetchSavedData();
      })
      .catch(() => alert("Error submitting data!"));
  };
  

  const fetchSavedData = async () => {
    setIsLoading(true); 
    if (id) {
      try {
        const formData = new FormData();
        formData.append("user_id", id);
        formData.append("week_no", financialWeek);
        formData.append("year", year_data);
        formData.append("updated_user_id", localStorage.getItem("mst_users_id"));

        const response = await axios.post(
          `${API_BASE_URL}/Timesheet/get_all_timesheet_week_wise`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } }
        );

        if (response.data?.data) {
          const fetchedData = response.data.data.map((row) => ({
            ...row,
            hours: row.hours || { Mon: "", Tue: "", Wed: "", Thu: "", Fri: "" },
            total: row.total || 0,
          }));
          setData(fetchedData);

          var dropdown = document.getElementById("filter_block");

          // Remove the 'show' class
          dropdown.classList.remove("show");
          

        } else {
          setData([{ project: "", hours: { Mon: "", Tue: "", Wed: "", Thu: "", Fri: "" }, total: 0 }]);
        }
      } catch (error) {
        console.error("Error fetching saved data:", error);
      }
    }
    setIsLoading(false); // Stop loading
  };

  useEffect(() => {
    fetchSavedData();
  }, [financialWeek, API_BASE_URL, id]);

  const deleteRowTable = async (user_id, project_id, week_no) => {
    if(!confirm("Are you sure want to delete..?")){
      return false;
    }
    console.log("user_id " + user_id);
    console.log("project_id " + project_id);
    console.log("week_no " + week_no);
  
    setIsLoading(true); // Show loading indicator
  
    try {
      const formData = new FormData();
      formData.append("user_id", user_id); // Use the passed `user_id` parameter
      formData.append("week_no", week_no); // Use the passed `week_no` parameter
      formData.append("updated_user_id", localStorage.getItem("mst_users_id"));
      formData.append("project_id", project_id); // Use the passed `project_id` parameter
  
      // Make the API request
      const response = await axios.post(
        `${API_BASE_URL}/Timesheet/get_delete`, // Ensure this is the correct API endpoint
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
      fetchSavedData();
  
     console.log(response);
    } catch (error) {
      console.error("Error :", error);
    }
  
    setIsLoading(false); // Hide loading indicator
  };
  
  const addRow = () => {
    // Define new row data structure
    const newRow = {
      project: "",  // Initially empty for the user to select a project
      hours: { Mon: "", Tue: "", Wed: "", Thu: "", Fri: "" },  // Empty hours initially
      total: 0,  // Initially, total is set to 0
      mst_projects_id: "",  // Initially empty for the project selection
    };
  
    // Get the table body where the rows will be added
    const tableBody = document.getElementById('week_table').getElementsByTagName('tbody')[0];
  
    // Create a new row in the table body
    const newRowElement = tableBody.insertRow();
  
    // Create the project select cell (first column)
    const projectCell = newRowElement.insertCell(0);
    const projectSelect = document.createElement('select');
    const defaultOption = document.createElement('option');
    defaultOption.value = "";
    projectSelect.name = "project_id[]";
    projectSelect.className = "project_id";
    defaultOption.textContent = "Select Project";
    projectSelect.appendChild(defaultOption);
  
    // Dynamically add options based on the `projects` array
    projects.forEach((projectOption) => {
      const option = document.createElement('option');
      option.value = projectOption.mst_projects_id;
      option.textContent = projectOption.project_name;
      projectSelect.appendChild(option);
    });
  
    projectSelect.addEventListener('change', (e) => {
      const selectedProject = e.target.value;  // Get the selected project ID
      const row = e.target.closest('tr');  // Find the closest row to the select box
  
      // Update newRow with selected project ID
      newRow.project = selectedProject;  // Update the project selection
      newRow.mst_projects_id = selectedProject;  // Store project ID in mst_projects_id
  
      // Find the input[project_id=''] inside the closest row
      const projectInput = row.querySelector("input[project_id='']");  // Use the correct selector
  
      // If the input field exists, update its value with the selected project ID
      if (projectInput) {
          projectInput.value = selectedProject;  // Set the project ID in the input field
      }
  
      // Add the project_id attribute to all input fields in the closest row
      const rowInputs = row.querySelectorAll('input');  // Select all input fields in the row
      rowInputs.forEach(input => {
          input.setAttribute('project_id', selectedProject);  // Set the project_id attribute for each input
      });
  
      // If a project is selected, check if it is already selected in another row
      if (newRow.mst_projects_id !== "") {
          const allSelects = document.querySelectorAll('select');  // Select all project selects in the table
          let isProjectAlreadySelected = false;
  
          // Check if the project is already selected in another row
          allSelects.forEach(select => {
              if (select !== projectSelect && select.value === newRow.mst_projects_id) {
                  isProjectAlreadySelected = true;
              }
          });
  
          if (isProjectAlreadySelected) {
              alert("This project is already selected in another row.");
              newRow.project = "";  // Reset the selection
              newRow.mst_projects_id = "";  // Reset the project ID
              projectSelect.value = "";  // Clear the project select value
          }
      }
  
      // Disable the project options that have already been selected in another row
      const allSelects = document.querySelectorAll('select');
      allSelects.forEach(select => {
          if (select !== projectSelect) {  // Skip the current select being changed
              Array.from(select.options).forEach(option => {
                  // Disable options that are already selected in another row
                  option.disabled = Array.from(allSelects).some(otherSelect => 
                      otherSelect !== select && otherSelect.value === option.value
                  );
              });
          }
      });
    });
  
  
    projectCell.appendChild(projectSelect);
  
   
    weekDates.forEach((date, dayIndex) => {
      const formattedDate = format(date, "yyyy-MM-dd");
     // console.log("formattedDate " + formattedDate);
  
      const hoursCell = newRowElement.insertCell(dayIndex + 1); // This will create cells 1 to 5
      const hoursInput = document.createElement('input');
      
      // Set the input type
      hoursInput.type = 'number';
      
      // Set the initial value
      hoursInput.value = newRow.hours[date] || ''; // Initially empty or from newRow.hours[day]
      
      // Add className for styling (e.g., 'hours-input')${date.toLowerCase()}-input
      hoursInput.className = `hours-input form-control date_${formattedDate}`; // Example: 'hours-input mon-input'
      hoursInput.project_id = '';  // Add the project_id attribute with an empty value
      hoursInput.date = formattedDate;
     
      hoursInput.name = `hrs[]`; // Name attribute for form data submission (e.g., 'hrs[Mon]')
  
      // Add event listener to update total when the value changes
      hoursInput.addEventListener('input', updateTotal);
      hoursInput.addEventListener('keyup', validateInput);
      hoursInput.addEventListener('change', validateInput);

      // Append the input element to the cell
      hoursCell.appendChild(hoursInput);
    });
  
    // Create the total cell (after the weekdays)
    const totalCell = newRowElement.insertCell(6); // This will create cell 6 for the total
    const totalText = document.createElement('span');
    totalText.textContent = newRow.total;  // Display the total as text
    totalCell.appendChild(totalText);
  
    // Create the Action (Delete) button cell (last column)
    const actionCell = newRowElement.insertCell(7); // This will create cell 7 for the delete button
    const deleteButton = document.createElement('button');
    deleteButton.textContent = 'x';
    deleteButton.classList.add('btn', 'btn-danger');
    deleteButton.onclick = () => deleteRow(newRowElement);
    actionCell.appendChild(deleteButton);
  
    // Function to update the total based on the hours entered
    function updateTotal() {
      //const valuess = parseFloat(input.value) || 0;

      
     
      var bool = false;
      weekDates.forEach((date) => {

        const formattedDate = format(date, "yyyy-MM-dd");
       
        const rows_datewise = document.querySelectorAll(`#week_table tbody tr .date_${formattedDate}`);
        // Initialize total hours for the current date
        let hrs = 0;
        // Loop through rows for the current date
        rows_datewise.forEach((row) => {
          hrs += parseFloat(row.value) || 0; // Add row value (parseFloat handles empty or invalid values)
          if (hrs > 9) {
            alert("Total hours for " + formattedDate + " exceed 9!");

            // Clear the value of the input field that caused the overflow
            row.value = "";

            bool = true;
          }
        });

      });

      // If any date exceeds the limit, prevent further action
      if (bool) {
        return false;
      }

      let total = 0;
      const hoursInputs = newRowElement.querySelectorAll('input[type="number"]');

      hoursInputs.forEach(input => {
        const value = parseFloat(input.value) || 0;
        total += value;
      });
  
      // Update the total in the total text span
      totalText.textContent = total;
      newRow.total = total;  // Update the total in the row data
    }
  
    // Function to delete the row
    function deleteRow(row) {
      row.remove();
    }
  };


  function validateInput() {
    console.log("Validating total hours...");
    
    // Get all inputs with the 'hours-input' class
    const allInputs = document.querySelectorAll(".hours-input");
  
    // Initialize total hours
    let totalHours = 0;
  
    // Loop through each input and add its value to totalHours
    allInputs.forEach(input => {
      const value = parseFloat(input.value) || 0; // Parse value or default to 0
      totalHours += value;
    });
  
    // Update the total hours display in the HTML
    const totalHoursSpan = document.getElementById("total_hours_span");
    if (totalHoursSpan) {
      totalHoursSpan.textContent = totalHours || 0; // Reset to 0 if no inputs found
      console.log("Total hours updated:", totalHours || 0);
    } else {
      console.warn("Element with ID 'total_hours_span' not found.");
    }
  }
  
  useEffect(() => {
    const observer = new MutationObserver(() => {
      const inputs = document.querySelectorAll(".hours-input");
      if (inputs.length > 0) {
        console.log("MutationObserver: Detected inputs, attaching event listeners.");
        
        // Attach event listeners
        inputs.forEach(input => {
          input.addEventListener("input", validateInput); // 'input' event captures all changes
        });
  
        validateInput(); // Initial validation on DOM change
      } else {
        // Reset the total hours when no inputs are present
        const totalHoursSpan = document.getElementById("total_hours_span");
        if (totalHoursSpan) {
          totalHoursSpan.textContent = 0; // Reset total to 0 when no inputs are present
          console.log("Total hours reset to 0.");
        }
      }
    });
  
    // Observe changes in the DOM
    observer.observe(document.body, {
      childList: true, // Watch for direct children changes
      subtree: true, // Watch for changes in all descendants
    });
  
    return () => {
      observer.disconnect(); // Disconnect observer on cleanup
      console.log("MutationObserver disconnected.");
  
      // Clean up event listeners
      const inputs = document.querySelectorAll(".hours-input");
      inputs.forEach(input => {
        input.removeEventListener("input", validateInput);
      });
      console.log("Event listeners removed.");
    };
  }, [currentWeekStart]); // Re-run whenever the week changes
  

  let hasRenderedFalse = false;

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
 // Toggle the visibility of the dropdown
 const filter_dropdown = (event) => {
  event.stopPropagation(); // Prevent the click from propagating to the body
  setIsDropdownVisible(!isDropdownVisible); // Toggle visibility state
};
// Add event listener to the body to close the dropdown when clicking outside
useEffect(() => {
  const handleBodyClick = (event) => {
    const dropdown = document.getElementById("filter_block");
    const button = document.getElementById("filter_button");

    // Check if the click is outside the dropdown and button
    if (!dropdown.contains(event.target) && event.target !== button) {
      setIsDropdownVisible(false); // Close the dropdown
    }
  };

  // Add event listener when the component mounts
  document.body.addEventListener("click", handleBodyClick);

  // Clean up event listener when the component unmounts
  return () => {
    document.body.removeEventListener("click", handleBodyClick);
  };
}, []);

// function filter_dropdown() {
//     console.log("clicked");

//     var dropdown = document.getElementById("filter_block");

//     // Toggle the 'show' class to trigger fade in/out
//     dropdown.classList.toggle("show");
// }





  function filter_btn() {

    const select_year = parseInt(document.getElementsByClassName("select_year")[0]?.value);
    const select_month = parseInt(document.getElementsByClassName("select_month")[0]?.value);
    if(select_year==''){
      alert("Please select Year..?");
      return false;
    }
    if(select_month==''){
      alert("Please select Month..?");
      return false;
    }

    const selectedWeekNumber = parseInt(document.getElementsByClassName("get_week_value")[0]?.value, 10);
    if(selectedWeekNumber==''){
      alert("Please select Week Number..?");
      return false;
    }
   

    if (!selectedWeekNumber || isNaN(selectedWeekNumber)) {
        console.error("Invalid or no week number selected.");
        return;
    }

    // Calculate the start date of the financial year
    const financialYearStart = new Date(`${filterYear}-04-01`);
    const weekStartDate = addDays(financialYearStart, (selectedWeekNumber - 1) * 7);
    const weekEndDate = addDays(weekStartDate, 6);

    console.log("Week Number: " + selectedWeekNumber);
    console.log("filterYear: " + filterYear);
    console.log("Week Start Date: " + weekStartDate.toISOString().split("T")[0]);
    console.log("Week End Date: " + weekEndDate.toISOString().split("T")[0]);
     financialWeek = selectedWeekNumber;
    // You can set the current week state here if needed
    setCurrentWeekStart(weekStartDate);
   
    const filterYearNumber = parseInt(select_year); // Ensure filterYear is treated as a number
    console.log("select_year "+select_year);
    console.log("select_month "+select_month);
    if (select_month === '01' || select_month === '02' || select_month === '03' || select_month === '1' || select_month === '2' || select_month === '3' || select_month === 1 || select_month === 2 || select_month === 3) {
      year_data = filterYearNumber + 1; // Increment the year for January, February, March
    } else {
      year_data = filterYearNumber; // Use the current year for other months
    }
    
   
    fetchSavedData();
    // Perform any further actions with the selected week number, start, and end dates
  }


  
const filterCalculateFinancialYearWeeks = (filterYear, filterMonth) => {
  const filterIsPreviousFinancialYear = filterMonth === '01' || filterMonth === '02' || filterMonth === '03';
  console.log("filterIsPreviousFinancialYear "+filterIsPreviousFinancialYear);
  const filterFinancialYearStart = new Date(`${filterIsPreviousFinancialYear ? filterYear - 1 : filterYear}-04-01`);
  
  const filterWeekNumbers = [];
  const filterStartDate = new Date(`${filterYear}-${filterMonth < 10 ? '0' + filterMonth : filterMonth}-01`);
  const filterEndDate = new Date(filterStartDate.getFullYear(), filterMonth, 0);

  let filterCurrentDate = filterStartDate;
  while (filterCurrentDate <= filterEndDate) {
    const filterFirstDayOfWeek = new Date(filterCurrentDate);
    filterFirstDayOfWeek.setDate(filterCurrentDate.getDate() - filterCurrentDate.getDay());

    const filterTimeDifference = filterFirstDayOfWeek - filterFinancialYearStart;
    let filterWeekNumber = Math.ceil((filterTimeDifference / (1000 * 3600 * 24)) / 7) + 1;

    filterWeekNumbers.push({
      week: filterWeekNumber,
      startDate: filterFirstDayOfWeek.toISOString().split('T')[0],
      endDate: new Date(filterFirstDayOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    });

    filterCurrentDate.setDate(filterCurrentDate.getDate() + 7);
  }

  return filterWeekNumbers;
};

const [filterYear, setFilterYear] = useState(2024);
const [filterMonth, setFilterMonth] = useState('');
const [filterWeekData, setFilterWeekData] = useState([]);
const [filterSelectedWeek, setFilterSelectedWeek] = useState('');

const currentYear = new Date().getFullYear();
const filterYears = [];
for (let y = 2019; y <= currentYear; y++) {
    filterYears.push(y);
}



const filterHandleYearChange = (e) => {
    setFilterYear(e.target.value);
    setFilterMonth('');
    setFilterWeekData([]);
    setFilterSelectedWeek('');
    //setFilterCurrentWeekIndex(0); // Reset the week index when the year changes
};

const filterHandleMonthChange = (e) => {
    setFilterMonth(e.target.value);
    setFilterWeekData(filterCalculateFinancialYearWeeks(filterYear, e.target.value));
    setFilterSelectedWeek('');
    //setFilterCurrentWeekIndex(0); // Reset the week index when the month changes
};

const filterHandleWeekChange = (e) => {
    setFilterSelectedWeek(e.target.value);
  
};

const filterMonths = [
   
  { name: 'April', value: '04' },
  { name: 'May', value: '05' },
  { name: 'June', value: '06' },
  { name: 'July', value: '07' },
  { name: 'August', value: '08' },
  { name: 'September', value: '09' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
  
  { name: 'January', value: '01' },
  { name: 'February', value: '02' },
  { name: 'March', value: '03' },
];

const getFinancialYearMonths = () => {
  const currentYear = parseInt(filterYear);

  // Filter months from April to December for the current year
  const monthsForCurrentYear = filterMonths.filter((m) => m.value >= '04');
  
  // Filter months from January to March for the next year
  const monthsForNextYear = filterMonths.filter((m) => m.value <= '03');

  // Combine the months, adjusting for the year as needed
  return [
    ...monthsForCurrentYear.map(m => ({
      ...m,
      name: `${m.name} ${currentYear}`,
      value: m.value
    })),
    ...monthsForNextYear.map(m => ({
      ...m,
      name: `${m.name} ${currentYear + 1}`,
      value: m.value
    }))
  ];
};

const current_week_date = new Date();

  return (
    <div className="main-content">
      <Header employeeName='' />
      <div className="page-content">
        <div className="container-fluid">

          <div className="row">
              <div className="card">
                <div className="card-header align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">Timesheet</h4>
                </div>
                <div className="card-body ">

                    <div className="" style={{ fontFamily: "Arial", textAlign: "center", margin: "0px" }}>

                      <div  style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                        
                        <button className="btn btn-success prev_week btn_navigation" onClick={() => navigateWeek("prev")}>
                          &lt; Prev
                        </button>
                        <h3 style={{ margin: "0" }}>
                          {format(currentWeekStart, "MMMM yyyy") } - Week {financialWeek}
                        </h3>

                      
                        <button className="btn btn-success next_week btn_navigation" onClick={() => navigateWeek("next")}>
                          Next &gt;
                        </button>

                      

                      </div>
                      <div className="row">

                        <div className="col-lg-4 text-left" current_week_date={current_week_date}>

                          {new Date(current_week_date) > new Date(currentWeekStart) && (
                            <button
                              className="btn btn-primary btns"
                              onClick={addRow}
                              style={{ margin: "10px",  }}
                            >
                            <i className=" ri-add-fill align-middle"></i>  Add Row
                            </button>
                          )}

                        </div>
                        <div className="col-lg-4 text-center">
                        
                          {projects.map((project, index) => {
                            // If the first project has been rendered, return null for subsequent ones
                            if (hasRenderedFalse) {
                              return null;
                            }

                            // Use filter to find all matching rows
                            const matchingRows = data.filter((row) => 
                              row.mst_projects_id === project.mst_projects_id && 
                              String(row.week_no) === String(financialWeek)
                            );

                            // If we have matching rows, pick the first one (or handle as needed)
                            if (matchingRows.length > 0) {
                              // For now, use the first matching row. You can adjust this logic as per your requirement.
                              const projectData = matchingRows[0];  // Use the first match (or you can use other logic)
                              
                            
                              const status = projectData.status;  // Access status

                              // Check if the status exists and render accordingly
                              if (status === "0") {
                                hasRenderedFalse = true;  // Mark as rendered
                                return (
                                  <span style={{ margin: "15px 0px 0px 0px " }} key={index} className="btn btn-sm btn-warning">
                                    Draft
                                  </span>
                                );
                              }

                              if (status === "1") {
                                hasRenderedFalse = true;  // Mark as rendered
                                return (
                                  <span style={{ margin: "15px 0px 0px 0px " }} key={index} className="btn btn-sm btn-primary">
                                    Submitted
                                  </span>
                                );
                              }

                              if (status === "2") {
                                hasRenderedFalse = true;  // Mark as rendered
                                return (
                                  <span style={{ margin: "15px 0px 0px 0px " }} key={index} className="btn btn-sm btn-success">
                                    Approved
                                  </span>
                                );
                              }
                            } 

                            return null;  // If no match, skip this project
                          })}

                        </div>
                        <div className="col-lg-4 text-right">
                            <a href="" className="btn btn-primary btns"><i className=" ri-repeat-line align-middle mx-1"></i>Refresh</a>
                            <button className="btn btn-primary btns filter_dropdown" id="filter_dropdown" style={{ margin: "10px" }} onClick={(event) => filter_dropdown(event)} >
                              Filter <i className="mdi mdi-chevron-down align-middle ms-1"></i>
                            </button>
                            <div className="col-lg-12">
                              <div className={`dropdown-content filter_block ${isDropdownVisible ? "show" : ""}`}  id="filter_block" style={{ padding: '10px 20px' }}>
                                <div className="col-lg-12 form-group">
                                  <select className="form-control select_year" value={filterYear} onChange={filterHandleYearChange}>
                                      {filterYears.map((y) => (
                                          <option key={y} value={y}>
                                              {y}-{y + 1}
                                          </option>
                                      ))}
                                    
                                  </select>
                                </div>
                                <div className="col-lg-12 form-group">
                                    <select className="form-control select_month "  value={filterMonth} onChange={filterHandleMonthChange} disabled={!filterYear}>
                                      <option value="">Select Month</option>
                                      {getFinancialYearMonths(filterYear).map((m) => (
                                          <option key={m.value} value={m.value}>
                                              {m.name}
                                          </option>
                                      ))}
                                  </select>

                                </div>
                                <div className="col-lg-12 form-group">
                                  {filterWeekData.length > 0 && (
                                    <div>                         
                                          <select
                                            className="get_week_value  form-control" 
                                            value={filterSelectedWeek}
                                            onChange={filterHandleWeekChange}
                                            disabled={!filterMonth}
                                        >
                                            <option value="">Select Week</option>
                                            {filterWeekData.map((week, index) => (
                                                <option key={index} value={week.week}>
                                                    Week {week.week} 
                                                </option>
                                            
                                            ))}
                                        </select>

                                        </div>
                                    )}
                                  </div>
                                  <div className="col-lg-12 form-group">
                                      <button type="button" onClick={() => filter_btn()} className="btn btn-sm btn-primary">Search</button>
                                  </div>
                              </div>
                            
                            </div>
                        </div>
                      </div>
                      <div className="row">
                            <div className="col-lg-12 o-auto">

                                {isLoading ? (
                                  <div>Loading...</div>
                                ) : (
                                  <table className="table table-stripped table-bordered week_table" id="week_table" style={{ width: "100%" }}>
                                    <thead>
                                      <tr>
                                        <th>{/* Project Name is shown in the select box */}</th>
                                        {weekDates.map((date, index) => (
                                          <th key={index}>{format(date, "EEE, dd MMM")}</th>
                                        ))}
                                        <th>Total</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {projects.map((project, index) => {
                                        const projectData = data.filter((row) => row.mst_projects_id === project.mst_projects_id);
                                        if (projectData.length === 0) return null;

                                        return (
                                          <tr key={index}>
                                            <td>
                                              <select className="project_id" name="project_id[]"
                                                value={projectData[0]?.mst_projects_id || ""}
                                                onChange={(e) => {
                                                  const updatedData = [...data];
                                                  const newProjectId = e.target.value;
                                                  const rowIndex = updatedData.findIndex(
                                                    (row) => row.mst_projects_id === project.mst_projects_id
                                                  );
                                                  if (rowIndex !== -1) {
                                                    updatedData[rowIndex] = { ...updatedData[rowIndex], mst_projects_id: newProjectId };
                                                  }
                                                  setData(updatedData);
                                                }}
                                                disabled={projectData[0]?.mst_projects_id !== ""} // Disable if project is already selected
                                              >
                                                <option value="">Select Project</option>
                                                {projects.map((projectOption, projectIndex) => (
                                                  <option key={projectIndex} value={projectOption.mst_projects_id}>
                                                    {projectOption.project_name}
                                                  </option>
                                                ))}
                                              </select>
                                            </td>
                                          

                                            {/* Render other columns for the week */}
                                            {weekDates.map((date, dayIndex) => {
                                              const formattedDate = format(date, "yyyy-MM-dd");
                                              const rowForDate = data.find(
                                                (row) =>
                                                  row.mst_projects_id === project.mst_projects_id && row.date === formattedDate
                                              );

                                              return (
                                                <td key={dayIndex}>
                                                <input type="hidden" value={formattedDate} class="date" name="date[]" />
                                              
                                                <input
                                                  name="hrs[]"
                                                  className={`hours form-control hours-input date_${formattedDate}`}
                                                  type="number"
                                                  project_id={project.mst_projects_id}
                                                  mst_timesheets_id={rowForDate ? rowForDate.mst_timesheets_id : ""}
                                                  value={rowForDate ? rowForDate.hrs : ""}
                                                  onKeyUp={validateInput}
                                                  onChange={(e) => {
                                                    const newHours = parseFloat(e.target.value) || 0; // New input value
                                                    const updatedData = [...data];
                                              
                                                    // Find the index of the row to update
                                                    let rowIndex = updatedData.findIndex(
                                                      (row) =>
                                                        row.mst_projects_id === project.mst_projects_id && row.date === formattedDate
                                                    );
                                              
                                                    // Calculate the current total hours for the same date
                                                    const currentTotalForDate = updatedData
                                                      .filter((row) => row.date === formattedDate)
                                                      .reduce((sum, row) => sum + (parseFloat(row.hrs) || 0), 0);
                                              
                                                    // Exclude the current row's hours if it exists
                                                    const existingHours = rowIndex !== -1 ? parseFloat(updatedData[rowIndex].hrs) || 0 : 0;
                                              
                                                    // Check if adding new hours exceeds the limit
                                                    if (currentTotalForDate - existingHours + newHours > 9) {
                                                      alert(`Total hours for ${formattedDate} cannot exceed 9.`);
                                                      return; // Prevent updating data
                                                    }
                                              
                                                    if (rowIndex === -1) {
                                                      // If no row exists for this date, create one
                                                      updatedData.push({
                                                        mst_projects_id: project.mst_projects_id,
                                                        date: formattedDate,
                                                        hrs: newHours.toString(),
                                                        total: 0,
                                                      });
                                                      rowIndex = updatedData.length - 1; // Update the index to the new row
                                                    } else {
                                                      // Update the existing row
                                                      updatedData[rowIndex].hrs = newHours.toString();
                                                    }
                                              
                                                    // Recalculate the total hours for the project
                                                    const total = updatedData
                                                      .filter((row) => row.mst_projects_id === project.mst_projects_id)
                                                      .reduce((sum, row) => sum + (parseFloat(row.hrs) || 0), 0);
                                              
                                                    // Update the total for all rows of the same project
                                                    updatedData.forEach((row) => {
                                                      if (row.mst_projects_id === project.mst_projects_id) {
                                                        row.total = total;
                                                      }
                                                    });
                                              
                                                    // Update the data state
                                                    setData(updatedData);
                                                  }}
                                                />
                                              </td>
                                              
                                              );
                                            })}

                                            {/* Total column */}
                                            <td>
                                            {/* Calculate and display the total sum of hours for this project and date */}
                                            {data
                                              .filter((row) => row.mst_projects_id === project.mst_projects_id) // Filter by project ID
                                              .reduce((sum, row, index, filteredRows) => {
                                                const formattedDate = format(new Date(row.date), "yyyy-MM-dd");

                                                // Find the first row that matches both project and date
                                                if (weekDates.some((date) => format(date, "yyyy-MM-dd") === formattedDate)) {
                                                  // Check if it's the first occurrence of the project-date combination
                                                  const isFirstRow = filteredRows.findIndex(
                                                    (filteredRow) => filteredRow.mst_projects_id === row.mst_projects_id && filteredRow.date === row.date
                                                  ) === index;

                                                  // Only add hours from the first matching row for this project and date
                                                  if (isFirstRow) {
                                                    return sum + (parseFloat(row.hrs) || 0);
                                                  }
                                                }
                                                return sum;
                                              }, 0)
                                              .toFixed(2)}
                                          </td>

                                            {/* Action (delete) */}
                                            <td>
                                              <button onClick={() => deleteRowTable(id,project.mst_projects_id,financialWeek)} className="btn btn-danger btns">
                                              <i className=" ri-delete-bin-5-line align-middle "></i>
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      })}

                                    </tbody>

                                  </table>
                                )}
                            </div>
                      </div>

                      <button className="btn btn-primary" onClick={() => handleSubmit("0")}>
                        Save as Draft
                      </button>

                      {localStorage.getItem("mst_roles_id") === "4" && (
                        <button className="btn btn-success" onClick={() => handleSubmit("1")}>
                          Submit
                        </button>
                      )}
                      {localStorage.getItem("mst_roles_id") === "3" && (
                        <button className="btn btn-success" onClick={() => handleSubmit("2")}>
                          Approve and Submit
                        </button>
                      )}

                    

                    </div>
                    <strong> Total Hours : <span id="total_hours_span" className="total_hours_span" style={{ padding: "10px",margin:"2px" }}></span></strong>
                </div>
              </div>
          
          </div>


          
        </div>
      </div>
    </div>
  );
};

export default FinancialYearDataEntry;
