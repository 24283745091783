import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

// Toaster notifications
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Generate a global nonce
const nonce = generateNonce();

function generateNonce() {
  return btoa(new Date().getTime().toString() + Math.random());
}

// Function to dynamically apply the nonce to every script, link, and style
(function applyNonceToScriptsLinksAndStyles() {
  // Apply nonce to all <script> tags
  const scripts = document.querySelectorAll('script');
  scripts.forEach((script) => {
    script.setAttribute('nonce', nonce);
  });

  // Apply nonce to all <link> tags
  const links = document.querySelectorAll('link');
  links.forEach((link) => {
    link.setAttribute('nonce', nonce);
  });

  // Apply nonce to all <style> tags (inline styles)
  const styles = document.querySelectorAll('style');
  styles.forEach((style) => {
    style.setAttribute('nonce', nonce);
  });


  // Also update the CSP meta tag with the generated nonce
  const cspMetaTag = document.querySelector('meta[http-equiv="Content-Security-Policy"]');
  if (cspMetaTag) {
    cspMetaTag.setAttribute('content', cspMetaTag.getAttribute('content').replace('nonce-PLACEHOLDER', nonce));
  }
})();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      {/* Main App Component */}
      <App />

      {/* ToastContainer */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        pauseOnHover
        draggable
        pauseOnFocusLoss
        theme="light"
        limit={3}
      />
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
