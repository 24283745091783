import React, { useState, useEffect, useRef } from "react";
import $ from 'jquery'; // Import jQuery
import 'jquery-validation'; // Import jQuery Validation Plugin
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import axios from "axios";
import { Link } from 'react-router-dom';

const Project = () => {
    const [formData, setFormData] = useState({ mst_departments_id: '', company_code: '', project_name: '', mst_projects_id: '', profit_center: '' });
    const [data, setData] = useState([]);
    // const [departments, setDepartments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    // const jwtToken = localStorage.getItem('token');

    useEffect(() => {
        // Fetch departments and roles
        const fetchDepartments = async () => {
            try {
                // const [departmentsResponse] = await Promise.all([
                //     axios.get(`${API_BASE_URL}/Department/get_all`)
                // ]);

                //setDepartments(departmentsResponse.data.data);
            } catch (error) {
                console.error('Error fetching departments or roles:', error);
                toast.error('Error fetching departments or roles');
            }
        };

        fetchDepartments();
    }, [API_BASE_URL]);

    const formRef = useRef(null);

    useEffect(() => {
        $(formRef.current).validate({
            rules: {
                // mst_departments_id: {
                //     required: true
                // },
                company_code: {
                    required: true,
                    minlength: 3
                },
                project_name: {
                    required: true,
                },
                profit_center: {
                    required: true,
                }
            },
            messages: {
                // mst_departments_id: {
                //     required: "Please select department",
                // },
                company_code: {
                    required: "Please enter Company code",
                    minlength: "Project code should be at least 3 characters long"
                },
                project_name: {
                    required: "Please enter Project name",
                },
                profit_center: {
                    required: "Please enter Profit center",
                },
            },
            submitHandler: function () {
                handleSubmit();
            }
        });
    }, []);

    const handleSubmit = async () => {
       
        try {
            const formData1 = new FormData();
            //formData1.append("mst_departments_id", formRef.current.mst_departments_id.value);
            formData1.append("company_code", formRef.current.company_code.value);
            formData1.append("project_name", formRef.current.project_name.value);
            formData1.append("mst_projects_id", formRef.current.mst_projects_id.value);
            formData1.append("profit_center", formRef.current.profit_center.value);
            formData1.append("user_id", localStorage.getItem("mst_users_id") || "");
          
            let apiEndpoint = `${API_BASE_URL}/Project/add`;
            let method = 'post';

         
            const response = await axios({
                method: method,
                url: apiEndpoint,
                data: formData1,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });

            if (response.data.success === 1) {
                toast.success(response.data.message);
                fetchData(currentPage, perPage); // Reload data after successful submission
            } else {
                toast.error(response.data.message);
            }

            
            setFormData({ company_code: '', project_name: '', mst_projects_id: '', profit_center: '' });
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };

    const fetchData = async (page, perPage) => {
        setLoading(true);
        try {
            const response = await axios.get(`${API_BASE_URL}/Project/get_projects`, {
                params: {
                    page: page,
                    per_page: perPage
                }
            });
            setData(response.data.results); // Modify based on your API structure
            setTotalRows(response.data.total); // Assume the API returns total records
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage, perPage);
    }, [currentPage, perPage]);

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };

    const handleEdit = (row) => {
        setFormData({
            company_code: row.company_code,
            // mst_departments_id: row.mst_departments_id,
            project_name: row.project_name,
            mst_projects_id: row.mst_projects_id, // Ensure this ID is correctly populated
            profit_center: row.profit_center
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleDelete = async (row) => {
        if (window.confirm(`Are you sure you want to delete project: ${row.project_name}?`)) {
            try {
                const formData = new FormData();
                formData.append('id', row.mst_projects_id);
    
                await axios.post(`${API_BASE_URL}/Project/delete/${row.mst_projects_id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                fetchData(currentPage, perPage);
            } catch (error) {
                console.error('Error deleting project:', error);
            }
        }
    };
    const [selectedRows, setSelectedRows] = useState([]);

const handleRowSelect = (id) => {
    const newSelectedRows = selectedRows.includes(id)
        ? selectedRows.filter(rowId => rowId !== id) // Remove if already selected
        : [...selectedRows, id]; // Add if not selected
    setSelectedRows(newSelectedRows);
};

const handleSelectAll = (e) => {
    if (e.target.checked) {
        setSelectedRows(data.map(row => row.mst_projects_id)); // Select all rows
    } else {
        setSelectedRows([]); // Deselect all rows
    }
};

    
    const columns = [
        {
            name: 'SL.No.',
            selector: row => row.count,
            sortable: false,
        },
        {
            name: <input type="checkbox" onChange={handleSelectAll} />, // "Select All" checkbox
            cell: row => (
                <input 
                    type="checkbox" 
                    checked={selectedRows.includes(row.mst_projects_id)} 
                    onChange={() => handleRowSelect(row.mst_projects_id)} 
                />
            ),
            sortable: false,
        },
        
        
    {
        name: 'Company Code',
        selector: row => row.company_code,
        sortable: false,
    },
    {
        name: 'Project Name',
        selector: row => row.project_name,
        sortable: false,
    },
   
    {
        name: 'Profit center',
        selector: row => row.profit_center,
        sortable: false,
    },
    {
        name: 'Action',
        selector: row => (
            <div className="btn-group btn-group-sm material-shadow mt-2" role="group">
                <button onClick={() => handleEdit(row)} className="btn btn-primary material-shadow-none">
                    <i className="ri-edit-line"></i>
                </button>
                <button onClick={() => handleDelete(row)} className="btn btn-danger material-shadow-none">
                    <i className="ri-delete-bin-2-line"></i>
                </button>
            </div>
        ),
        sortable: false,
    }
    ];

    const DownloadProjects = async () => {
        if(!confirm("Are you sure want to Download Projects..?")){
          return false;
        }
        const apiEndpoint = `${API_BASE_URL}/Project/download_projects`;
      
        // Create a new FormData object (add any data or file you want to send)
        const formData = new FormData();
      
        // If you need to append data to the formData, you can do it like this:
        // formData.append('key', value);
      
        try {
          const response = await axios.post(apiEndpoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: 'blob', // This ensures the response is treated as a file (blob)
          });
      
          // Create a link element to trigger the file download
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(new Blob([response.data]));
          link.href = url;
          link.setAttribute('download', 'projects.csv'); // Change the filename as needed
          document.body.appendChild(link);
          link.click();
      
          // Clean up by removing the link element
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading department code:', error);
        }
      };


      const handleBulkDelete = async () => {
        if (window.confirm("Are you sure you want to delete the selected projects?")) {
            try {
                // Create FormData and append the 'ids' array to it
                const formData = new FormData();
                formData.append('ids', JSON.stringify(selectedRows));  // Convert the array to a JSON string for transmission
    
                const response = await axios.post(`${API_BASE_URL}/Project/bulk_delete`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data' // Set the correct content type for FormData
                    }
                });
    
                if (response.data.success === 1) {
                    toast.success(response.data.message);
                    fetchData(currentPage, perPage); // Reload data after successful bulk delete
                    setSelectedRows([]); // Clear selected rows after deletion
                } else {
                    toast.error(response.data.message);
                }
            } catch (error) {
                console.error('Error during bulk delete:', error);
                toast.error('Error during bulk delete');
            }
        }
    };
    
       
    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
                                <h4 className="mb-sm-0">Projects </h4>
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Masters</a></li>
                                        <li className="breadcrumb-item active">Projects</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xxl-4 col-lg-4">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Add Project</h4>                                    
                                </div>
                                <div className="card-body">
                                    <p className="text-muted">Fields marked with an asterisk (*) are compulsory.</p>
                                    <div className="live-preview">
                                        <form ref={formRef} id="myForm" onSubmit={handleSubmit}>
                                            <input type="hidden" id="mst_projects_id" name="mst_projects_id" className="form-control" value={formData.mst_projects_id} onChange={handleChange} placeholder="" />
                                            <div className="row">

                                                {/* <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="department" className="form-label">Department <span>*</span></label>

                                                        <select
                                                            className="form-select"
                                                            name="mst_departments_id"
                                                            value={formData.mst_departments_id}
                                                            onChange={(e) => setFormData({ ...formData, mst_departments_id: e.target.value })}
                                                        >
                                                            <option value="">Select Department</option>
                                                            {departments.map(department => (
                                                                <option key={department.mst_departments_id} value={department.mst_departments_id}>{department.department_name}</option>
                                                            ))}
                                                        </select>

                                                    </div>
                                                </div> */}

                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="company_code" className="form-label">Company Code <span>*</span></label>
                                                        <input type="text" id="company_code" name="company_code" className="form-control" value={formData.company_code} onChange={handleChange} placeholder="Company Code" />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="project_name" className="form-label">Project Name <span>*</span></label>
                                                        <input type="text" id="project_name" name="project_name" className="form-control" value={formData.project_name} onChange={handleChange} placeholder="Project Name" />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="mb-3">
                                                        <label htmlFor="profit_center" className="form-label">Profit Center <span>*</span></label>
                                                        <input type="text" id="profit_center" name="profit_center" className="form-control" value={formData.profit_center} onChange={handleChange} placeholder="Profit Center" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="text-end">
                                                        <button type="submit" className="btn btn-primary">Submit</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-8 col-lg-8">
                            <div className="card">
                                <div className="card-header align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">Projects</h4>
                                     <Link to="/UploadProject" className="btn btn-warning btn-sm"> Import CSV</Link>
                                     <button className="btn btn-sm btn-primary" onClick={DownloadProjects}>Download  Projects</button>
                                    

                                </div>
                                <div className="card-body">
                                <button 
                                        className="btn btn-danger" 
                                        onClick={handleBulkDelete} 
                                        disabled={selectedRows.length === 0}
                                    >
                                        Bulk Delete
                                    </button>
                                    <div className="live-preview mt-2">
                                        <DataTable
                                            columns={columns}
                                            data={data}
                                            progressPending={loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={totalRows}
                                            onChangeRowsPerPage={handlePerRowsChange}
                                            onChangePage={handlePageChange}
                                            customStyles={{
                                                headRow: {
                                                    style: {
                                                        backgroundColor: '#0f6839', // Dark Green background
                                                        color: 'white', // White text
                                                    },
                                                },
                                                headCells: {
                                                    style: {
                                                        color: 'white', // White text for the header cells
                                                        fontWeight: 'bold', // Bold text for header cells
                                                    },
                                                },
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Project;
