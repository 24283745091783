import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import { Link } from 'react-router-dom';
import DataTable from 'datatables.net-react';
import DT from 'datatables.net-dt';
import $ from 'jquery';
DataTable.use(DT);

const Users = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

   

    const [toastMessage, setToastMessage] = useState(null);

    useEffect(() => {
        // Check if there is a toast message in localStorage when the component mounts
        const message = localStorage.getItem('toastMessage');
        if (message) {
            setToastMessage(message); // Set the message in the state
            localStorage.removeItem('toastMessage'); // Clear the message from localStorage
        }
    }, []); // Empty dependency array ensures this runs only once on mount

    useEffect(() => {
        if (toastMessage) {
            toast.success(toastMessage); // Display the toast message
        }
    }, [toastMessage]); // Display toast when message is available

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${API_BASE_URL}/Users/get_users`);
                setData(response.data.results);
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error("Error fetching data!");
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [API_BASE_URL]);

    const columns = [
        { title: 'SL.No.', data: 'count' },
        {
            title: 'Action',
            render: (data, type, row) => {
                return `<a href="/edit-user?id=${row.mst_users_id}" class="btn_edit">Edit</a>`;
            }
        },
        { title: 'Employee ID', data: 'employee_id' },
        { title: 'Employee Name', data: 'employee_name' },
        { title: 'Employee E-mail', data: 'employee_email' },
        { title: 'Employee Mobile', data: 'employee_mobile' },
        { title: 'Department', data: 'mst_departments_id' },
        { title: 'Role', data: 'mst_roles_id' },
        { title: 'Delegate Employees', data: 'delegate_employees' },
        {
            title: 'Status',
            render: (data, type, row) => {
                let badgeClass = '';
                const status = (row.status || '').trim().toLowerCase();
                if (status === 'active') {
                    badgeClass = 'badge bg-success';
                } else if (status === 'inactive') {
                    badgeClass = 'badge bg-danger';
                } else {
                    badgeClass = 'badge bg-secondary';
                }
                return `<span class="${badgeClass}">${row.status}</span>`;
            }
        },
        { title: 'Created On', data: 'create_on' },
        { title: 'Created By', data: 'created_by' },
    ];

    useEffect(() => {
        $(".users_table").wrap("<div class='wrap_table'></div>");
        $(".wrap_table").css({
            "overflow-x": "auto",
        });
    }, [data]);


    
    const dowloadUsersList = async () => {
        if(!confirm("Are you sure want to Download Users List..?")){
          return false;
        }
        const apiEndpoint = `${API_BASE_URL}/Users/downloadUsersList`;
      
        // Create a new FormData object (add any data or file you want to send)
        const formData = new FormData();
      
        // If you need to append data to the formData, you can do it like this:
        // formData.append('key', value);
      
        try {
          const response = await axios.post(apiEndpoint, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            responseType: 'blob', // This ensures the response is treated as a file (blob)
          });
      
          // Create a link element to trigger the file download
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(new Blob([response.data]));
          link.href = url;
          link.setAttribute('download', 'userslist.csv'); // Change the filename as needed
          document.body.appendChild(link);
          link.click();
      
          // Clean up by removing the link element
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error downloading Userslist code:', error);
        }
    };


    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row page-title-box">
                        <div className="col-lg-3">
                            <div className="">
                                <h4 className="mb-sm-0">Users Master</h4>
                               
                            </div>
                        </div>
                        <div className="col-lg-9 text-right">
                        <Link to="/add-user" className="btn btn-primary btn-sm">Create New User</Link>
                        <Link to="/UploadUser" className="btn btn-warning btn-sm"> Import CSV</Link>
                        <button className="btn btn-sm btn-primary" onClick={dowloadUsersList}>Download  Users List</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            {loading ? (
                                // Loader component while data is loading
                                <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                // DataTable when data is loaded
                                <DataTable
                                    data={data}
                                    columns={columns}
                                    className="display custom-table users_table"
                                    paging={true}
                                    ordering={true}
                                    responsive={true}
                                    language={{
                                        loadingRecords: loading ? "Loading..." : "No data available",
                                        search: "",
                                        searchPlaceholder: "Search users..."
                                    }}
                                    options={{
                                        pageLength: 10,
                                        lengthChange: true,
                                        lengthMenu: [10, 25, 50, 100],
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Users;
