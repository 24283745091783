import { useEffect, useState } from "react";
import axios from "axios";
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import * as XLSX from 'xlsx'; 

const Dashboard = () => {
    const location = useLocation();
    const useQuery = () => {
        return new URLSearchParams(location.search);
    };

    const query = useQuery();
    const id = query.get("emp_id");

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [timesheets, setTimesheets] = useState([]);
    const [status, setStatus] = useState({ value: '0', label: 'Draft' });
    const [loading, setLoading] = useState(false); // State for loading

    const statusOptions = [
        { value: '0', label: 'Draft' },
        { value: '1', label: 'Submitted' },
        // { value: '2', label: 'Approved' }
    ];

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Show loader when fetching data
            const formData1 = new FormData();
            formData1.append("user_id", id); // Add your user_id here
            formData1.append("status", status.value);

            try {
                const response = await axios({
                    method: "POST",
                    url: `${API_BASE_URL}/Timesheet/get_all_timesheet_by_status`,
                    data: formData1,
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response.data.success) {
                    setTimesheets(response.data.data); // Set fetched data
                }
            } catch (error) {
                console.error("Error fetching timesheet data:", error);
            } finally {
                setLoading(false); // Hide loader after fetching data
            }
        };

        fetchData();
    }, [status, API_BASE_URL, id]);

    const handleStatusChange = (selectedOption) => {
        setStatus(selectedOption);
    };

    function export_excel() {
        // Get the current date and time in the format: YYYY-MM-DD_HH-mm-ss
        const currentDateTime = new Date().toLocaleString('en-GB', { 
            hour12: false, 
            year: 'numeric', 
            month: '2-digit', 
            day: '2-digit', 
            hour: '2-digit', 
            minute: '2-digit', 
            second: '2-digit' 
        }).replace(/[,:]/g, '-'); // Replacing characters not valid for file names
        
        // Sanitize the sheet name by removing invalid characters
        const sheetName = "Timesheet Report_" + currentDateTime;
     // Sanitize the sheet name by removing invalid characters
const sanitizedSheetName = sheetName.replace(/[\\/?:*[\]]/g, ''); // Remove invalid characters

        const truncatedSheetName = sanitizedSheetName.length > 31 ? sanitizedSheetName.substring(0, 31) : sanitizedSheetName;
    
        // Map timesheets data and convert status to human-readable text
        const exportData = timesheets.map(item => ({
            'Project Name': item.mst_projects_id,
            'Year': item.year,
            'Week No': item.week_no,
            //'Date': item.date,
            // 'Hrs': item.hrs,
            'Status': mapStatus(item.status)  // Map status to human-readable text
        }));
    
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        
        // Use the sanitized and truncated sheet name
        XLSX.utils.book_append_sheet(workbook, worksheet, truncatedSheetName);
        
        // Generate the file name with date and time
        const fileName = "timesheet_status_report_" + currentDateTime + ".xlsx";
        
        // Export the file
        XLSX.writeFile(workbook, fileName);
    }
    
    // Function to map status to human-readable text
    function mapStatus(status) {
        switch (status) {
            case '0':
                return 'Draft';   // Status 0 means Draft
            case '1':
                return 'Submitted'; // Status 1 means Submitted
            case '2':
                return 'Approved';  // Status 2 means Approved
            default:
                return 'Unknown';  // If status is not 0, 1, or 2
        }
    }
    

    return (
        <div className="main-content">
            <div className="page-content">
                <div className="container-fluid">
                    <div className="row mb-3 pb-1">
                        <div className="col-12">
                            <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                                <div className="flex-grow-1"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="col-lg-12">
                                        <div className="row">

                                            <div className="col-lg-9">
                                                <h4 className="card-title mb-0 flex-grow-1">Status Reports</h4>
                                            </div>
                                            <div className="col-lg-3 text-right">
                                                <div className="row text-right">
                                                    <div className="col-lg-8 text-left">
                                                            <Select
                                                                options={statusOptions}
                                                                value={status}
                                                                onChange={handleStatusChange}
                                                            />
                                                    </div>
                                                    <div className="col-lg-4">
                                                        <button onClick={export_excel} className="btn btn-sm btn-primary" type="button"><i className=" mr-5"></i>Export</button>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                  
                                </div>
                                
                                <div className="card-body">
                                    <div className="col-lg-12">
                                        <div className="table-responsive">
                                            {loading ? (
                                                <div className="d-flex justify-content-center my-3">
                                                    Loading...
                                                </div>
                                            ) : (
                                                <table className="table align-middle table-nowrap table-striped mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th scope="col">SL.No.</th>
                                                            <th scope="col">Project</th>
                                                            <th scope="col">Week No.</th>
                                                            <th scope="col">Year</th>
                                                            <th scope="col">Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {timesheets.map((timesheet, index) => (
                                                            <tr key={timesheet.mst_timesheets_id}>
                                                                <td>{index + 1}</td>
                                                                <td>{timesheet.mst_projects_id}</td>
                                                                <td>{timesheet.week_no}</td>
                                                                <td>{timesheet.year}</td>
                                                                <td>
                                                                    {timesheet.status === '0' && <span className="badge bg-secondary">Draft</span>}
                                                                    {timesheet.status === '1' && <span className="badge bg-primary">Submitted</span>}
                                                                    {timesheet.status === '2' && <span className="badge bg-success">Approved</span>}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
